import classNames from 'classnames'
import Img from 'gatsby-image'
import React from 'react'

import Button from 'components/button'
import PageHead from 'components/head/PageHead'
import PageWrapper from 'components/layout/page-wrapper'
import ModuleIntercom from 'components/layout/Footer/parts/intercom'
import defaultData from 'data/pages/Contents/contact-support.json'
import buildImageObject from 'js/utils/buildImgObject'
import { SUPPORT_URL, SUPPORT_EMAIL, VIP_SUPPORT_PLUS_ENABLED } from 'src/constants'

import 'static/contact-support/scss/styles.scss'

const background = {
  aspectRatio: 1.8,
  path: '/contact-support/images/',
  fileType: 'jpg',
  files: [
    { fileName: 'header-bg-ssize', width: 640 },
    { fileName: 'header-bg-msize', width: 1536 },
    { fileName: 'header-bg-lsize', width: 3200 },
  ],
}

const Item = ({ index, path, copy }) => {
  return (
    <a href={`${SUPPORT_URL}${path}`} className="col-lg-3 col-md-4 col-sm-6 col-12">
      <div className="x-contact-support-item">
        <span className={`x-contact-support-item__title x-contact-support-item__title--${index}`}>
          {copy}
        </span>
      </div>
    </a>
  )
}

const HeaderSection = ({ data }) => {
  return (
    <>
      <div className="x-contact-support__headings">
        <h1 className="x-contact-support__headings-heading">{data.h1}</h1>
        <p className="x-contact-support__headings-subheading">{data.copy}</p>
      </div>
      <div className="row">
        {data.items.map((item, i) => (
          <Item key={i + 1} index={i + 1} path={item.path} copy={item.copy} />
        ))}
      </div>
    </>
  )
}

const ContactSection = ({ data, onClick }) => {
  return (
    <>
      <div className="x-contact-support__headings">
        <h2 className="x-contact-support__headings-heading2">{data.h2}</h2>
        <p className="x-contact-support__headings-subheading">{data.copy}</p>
      </div>
      <div
        className={classNames('x-contact-support__items', {
          'x-contact-support__items--vip': VIP_SUPPORT_PLUS_ENABLED,
        })}
      >
        {data.items &&
          data.items.map((item, i) => (
            <div key={i} className="x-contact-support__items__item">
              {item.icon ? (
                <i className="x-contact-support__items__item-icon">
                  <svg version="1.1" viewBox="0 0 23 22" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <g fill="#fff" fillRule="nonzero">
                        <path d="m19.266 16.263c-0.029203-0.52346 0.14786-1.0371 0.49162-1.4261 1.0638-1.2016 1.3254-2.168 1.3254-4.6457 0-4.5242-3.7892-8.2309-8.5052-8.2309h-2.1562c-4.716 0-8.5052 3.7048-8.5052 8.2319 0 4.582 3.8429 8.4544 8.5052 8.4544h2.1562c0.79925 0 1.5391-0.20288 3.0782-0.78212 0.49278-0.18548 1.0384-0.15608 1.5094 0.081349l2.2569 1.1379-0.15621-2.8207zm-8.8445-16.263h2.1562c5.7548 0 10.422 4.5634 10.422 10.192 0 2.5483-0.23958 4.1723-1.8208 5.959l0.23288 4.1939c0.069 0.82819-0.77338 1.4417-1.565 1.1389l-3.5286-1.7799c-1.1615 0.43713-2.4217 0.90268-3.7404 0.90268h-2.1562c-5.7558 0-10.422-4.7859-10.422-10.414 0-5.6297 4.6661-10.193 10.422-10.193z" />
                      </g>
                    </g>
                  </svg>
                </i>
              ) : (
                <img
                  className="x-contact-support__photos"
                  src="/contact-support/images/support-photos.png"
                  alt={item.alt}
                />
              )}

              <div className="x-contact-support__headings">
                <h2 className="x-contact-support__items__heading">{item.h2}</h2>
                <p className="x-contact-support__items__subheading">{item.copy}</p>
              </div>

              {item.path ? (
                <Button copy={item.cta} size="largest" to={item.path} />
              ) : (
                <Button copy={item.cta} size="largest" onClick={onClick} />
              )}
            </div>
          ))}
      </div>
    </>
  )
}

const DisclaimerSection = ({ data }) => {
  return (
    <div className="x-contact-support__container">
      <div className="x-contact-support__txt">
        <p className="x-contact-support__txt-txt1">
          <svg
            className="x-contact-support__txt-txt1-icon"
            role="presentation"
            viewBox="0 0 27 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M26.4606466,22.73473 L14.2670983,0.416534778 C13.9631305,-0.138844926 13.0372918,-0.138844926 12.7333241,0.416534778 L0.539775671,22.73473 C0.394324058,23.0008316 0.4012918,23.3227286 0.558936961,23.581963 C0.716582122,23.8411975 1.00051761,24 1.30709825,24 L25.694195,24 C26.0007757,24 26.2847112,23.8411975 26.4414853,23.581963 C26.5991305,23.3218702 26.6060983,23.0008316 26.4606466,22.73473 Z"
                id="Shape"
                fill="#FF2626"
              />
              <path
                d="M14.9910968,7.71428571 L14.3692258,16.2857143 L12.6272903,16.2857143 L12.0054194,7.71428571 L14.9910968,7.71428571 Z M13.4982581,21.4285714 C12.6429677,21.4285714 11.9496774,20.7462857 11.9496774,19.9045714 C11.9496774,19.0628571 12.6429677,18.3805714 13.4982581,18.3805714 C14.3535484,18.3805714 15.0468387,19.0628571 15.0468387,19.9045714 C15.0468387,20.7462857 14.3535484,21.4285714 13.4982581,21.4285714 Z"
                fill="#FFFFFF"
              />
            </g>
          </svg>
          {data.copy}
        </p>
      </div>
    </div>
  )
}

function SupportPage({ location, pageName = 'contact-support', pageData = defaultData }) {
  // get page data.
  const { header, contact, vipContact, disclaimer } = pageData.content

  // Get URL parameters
  const intercomSettings = {}
  let showNewMessage = null
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.hash.substring(1))
    const params = ['issue_number', 'via', 'code']
    params.forEach((param) => {
      const value = urlParams.get(param)
      if (value) {
        intercomSettings[param] = value
        showNewMessage = true
      }
    })
    window.history.replaceState(null, '', window.location.pathname)
  }

  // Open Messenger.
  const handleOpenMessenger = () => {
    const messengerLoaded = document.querySelectorAll('#intercom-frame').length

    if (messengerLoaded) {
      window.Intercom('show')
    } else {
      window.location.href = `mailto:${SUPPORT_EMAIL}`
    }
  }

  return (
    <PageWrapper
      className="x-page-contact-support"
      headerProps={{ location, trackerPage: pageName }}
      removeFooter
    >
      <div className="x-header__bg-container rellax" data-rellax-speed="-4">
        <Img fluid={buildImageObject(background)} durationFadeIn={2000} className="x__header-bg" />
      </div>
      <div className="x-contact-support x__width">
        {!!header && <HeaderSection data={header} />}
        <ContactSection
          data={VIP_SUPPORT_PLUS_ENABLED ? vipContact : contact}
          onClick={handleOpenMessenger}
        />
        <DisclaimerSection data={disclaimer} />
      </div>
      <ModuleIntercom intercomSettings={intercomSettings} showNewMessage={showNewMessage} />
    </PageWrapper>
  )
}

export default SupportPage

// <head> component:
export function Head() {
  return <PageHead page="contact-support" />
}
